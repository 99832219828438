import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import TicketRow from "./TicketRow";
import Iconify from "../../../../components/Iconify";
import { DatePicker } from "@mui/x-date-pickers";
import { getTicketsDel } from "../../../../redux/slices/ticket";
import { fDateOnly } from "../../../../utils/formatTime";

const TicketsDel = ({ open, handleClose }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tickets, setTicketsWin] = useState([]);
  const [refCode, setRefCode] = useState("");
  const totalMontant =
    tickets?.reduce((total, ticket) => total + parseFloat(ticket.montant), 0) ||
    0;

  const fetchData = async () => {
    try {
      const data = await getTicketsDel(
        fDateOnly(startDate, "yyyy-MM-dd"),
        fDateOnly(endDate, "yyyy-MM-dd"),
        refCode
      );
      setTicketsWin(data.data);
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open, refCode]);

  const handleViewTicket = (ficheId) => {
    const url = `https://gamehousecenter.com/#/fiche-view/${ficheId}`;
    const popupWindow = window.open(
      url,
      "_blank",
      "noopener,noreferrer,width=400,height=770"
    );

    if (popupWindow) {
      popupWindow.focus();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">TicketsDelete</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Iconify icon={"eva:close-fill"} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", gap: 2, py: 2 }}>
          <DatePicker
            label="Debut"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="Fin"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <IconButton onClick={fetchData} aria-label="search">
            <Iconify icon={"eva:search-fill"} />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter ref_code"
          value={refCode}
          onChange={(e) => setRefCode(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Typography variant="h6">
          TOTAL Tickets Delete : {tickets.length}
        </Typography>
        <Typography variant="h6">
          TOTAL Montant : {totalMontant.toFixed(2)}
        </Typography>
        <List>
          {tickets.map((ticket, index) => (
            <TicketRow
              key={index}
              ticket={ticket}
              onViewTicket={handleViewTicket}
              handleClose={handleClose}
            />
          ))}
        </List>
        <Typography variant="h6">
          TOTAL Tickets Delete : {tickets.length}
        </Typography>
        <Typography variant="h6">
          TOTAL Montant : {totalMontant.toFixed(2)}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default TicketsDel;
