import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../../routes/paths";
import { ListItem, ListItemText, Divider, MenuItem } from "@mui/material";
import MoreMenu from "./MoreMenu";
import Iconify from "../../../../components/Iconify";
import { extractErrorTicketMessage } from "../../../../utils/error";
import { useSnackbar } from "notistack";
import { deleteTicket, payTicket } from "../../../../redux/slices/ticket";

const TicketRow = ({ ticket, onViewTicket, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openMenu, setOpenMenuActions] = useState(null);
  const [loading, setLoading] = useState("");
  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleActionClick = async (actionType) => {
    setLoading(actionType);
    try {
      if (actionType === "view") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        onViewTicket(ticket.ref_code);
      } else if (actionType === "replay") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate(PATH_DASHBOARD.general.replay_ticket(ticket?.ref_code));
      } else if (actionType === "pay") {
        await payTicket(ticket?.id);
      } else if (actionType === "delete") {
        await deleteTicket(ticket?.id);
      }
    } catch (e) {
      // console.log(e);
      const errorMessage = extractErrorTicketMessage(e);
      enqueueSnackbar(errorMessage || "An error occured!", {
        variant: "error",
      });
    } finally {
      setLoading("");
      handleClose();
      handleCloseMenu();
    }
  };

  return (
    <>
      <ListItem
        secondaryAction={
          <MoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => handleActionClick("view")}
                  disabled={loading === "view"}
                >
                  {loading === "view" ? (
                    <Iconify icon="mdi:loading" />
                  ) : (
                    <Iconify icon={"icomoon-free:ticket"} />
                  )}
                  Voir Ticket
                </MenuItem>
                <MenuItem
                  onClick={() => handleActionClick("replay")}
                  disabled={loading === "replay"}
                  sx={{ color: "info.main" }}
                >
                  {loading === "replay" ? (
                    <Iconify icon="mdi:loading" />
                  ) : (
                    <Iconify icon={"icon-park:tickets-two"} />
                  )}
                  Rejouer Ticket
                </MenuItem>
                {ticket?.is_win && (
                  <MenuItem
                    onClick={() => handleActionClick("pay")}
                    disabled={loading === "pay"}
                    sx={{ color: "warning.main" }}
                  >
                    {loading === "pay" ? (
                      <Iconify icon="mdi:loading" />
                    ) : (
                      <Iconify icon={"majesticons:ticket-check"} />
                    )}
                    Payer Ticket
                  </MenuItem>
                )}
                {!ticket?.is_win && !ticket?.is_delete && (
                  <MenuItem
                    onClick={() => handleActionClick("delete")}
                    disabled={loading === "delete"}
                    sx={{ color: "error.main" }}
                  >
                    {loading === "delete" ? (
                      <Iconify icon="mdi:loading" />
                    ) : (
                      <Iconify icon={"eva:trash-2-outline"} />
                    )}
                    Suppr. Ticket
                  </MenuItem>
                )}

              </>
            }
          />
        }
      >
        <ListItemText
          primary={
            <span
              style={{
                color: ticket.is_delete
                  ? "red"
                  : ticket.is_win
                  ? "green"
                  : "inherit",
              }}
            >
              #{ticket.ref_code} {ticket.tirage_name} ({ticket.created_on})
            </span>
          }
          secondary={
            <span
              style={{
                color: ticket.is_delete
                  ? "red"
                  : ticket.is_win
                  ? "green"
                  : "inherit",
              }}
            >
              Mise : {ticket.montant} / Gain : {ticket.perte} / Payer :{" "}
              {ticket.is_paid ? "Oui" : "Non"}
            </span>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
};

export default TicketRow;
